import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import {
  PRODUCT_ENUMS_NL,
  SOLUTIONS_NL,
  SOLUTION_DOMAIN_PRODUCT_CATEGORIES,
  SOLUTION_PRODUCT_CATEGORIES,
  Solution,
  getDomainFromSolution,
} from '@energiebespaarders/constants';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import _ from 'lodash';
import {
  getSchemaProducts___type_fields,
  getSchemaProducts___type_fields_type_enumValues,
} from '../../types/generated/getSchemaProducts';
import { productById_productById } from '../../types/generated/productById';
import { ProductCategory } from '../../types/graphql-global-types';
import { delimit } from '../utils';
import { formatBrandName } from '../utils/formatBrandName';

function getProductTitleById(id: string) {
  const query = gql`
    query productTitle($id: ID!) {
      productById(id: $id) {
        id
        title
      }
    }
  `;
  return (
    <Query query={query} variables={{ id }}>
      {({ data, loading }: any) => {
        if (loading) return '';
        if (data.productById) return data.productById.title;
      }}
    </Query>
  );
}

export interface InputFieldAttributes {
  max?: string;
  min?: string;
  step?: string;
  maxLength?: number;
}

export type ProductSpecObj = {
  key: string;
  label: string;
  prefix?: string;
  suffix?: string;
  width?: number;
  required?: boolean;
  format?: (v: string | number) => string | number | JSX.Element;
  placeholder?: string;
  divider?: number;
  rangeOptions?: InputFieldAttributes;
  validate?: (value: any, product?: productById_productById) => string;
  tooltip?: string;
  constructOptions?: (
    enumValues: readonly getSchemaProducts___type_fields_type_enumValues[],
    field: getSchemaProducts___type_fields,
    solution: Solution,
  ) => DropdownOption[];
};

/* !!! KEEP IN SYNC WITH CHILI !!! */
export const productSpecs = (
  category?: ProductCategory | null,
  solution?: Solution | '',
): ProductSpecObj[] => [
  // The schema determines the order in which these are mapped in the `ProductModal` and `AddProductModal`
  // General
  {
    key: 'id',
    label: 'Product ID',
  },
  {
    key: 'title',
    label: 'Naam',
    required: true,
  },
  {
    key: 'category',
    label: 'Categorie',
    required: true,
    width: 1 / 2,
    constructOptions: (enumValues, field, solution) => {
      // Only display the categories that are available for the current solution
      const solutionCategories = SOLUTION_DOMAIN_PRODUCT_CATEGORIES[getDomainFromSolution(solution)]
        .filter(
          cat =>
            (!cat.solutionSpecific || SOLUTION_PRODUCT_CATEGORIES[solution]?.includes(cat.name)) &&
            (cat.name as any) !== ProductCategory.CompletePvSystem,
        )
        .map(cat => cat.name);
      return solutionCategories.map(category => ({
        value: category,
        label: PRODUCT_ENUMS_NL[category],
      }));
    },
  },
  {
    key: 'solution',
    label: 'Maatregel',
    required: true,
    width: 1 / 2,
    constructOptions: () => {
      // Only display the categories that are available for the current solution
      return Object.entries(SOLUTIONS_NL).map(([solution, label]) => ({
        value: solution,
        label: label,
      }));
    },
  },
  {
    key: 'description',
    label: 'Omschrijving',
  },
  {
    key: 'internalDescription',
    label: 'Notities (INTERN)',
  },
  {
    key: 'priceUnit',
    label: 'Eenheid',
    format: v => PRODUCT_ENUMS_NL[v as string],
    width: 1 / 2,
    required: true,
  },
  {
    key: 'tax',
    label: 'BTW',
    format: v => (v as number) * 100,
    suffix: '%',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'customMarkup',
    width: 1 / 2,
    label: 'Productspecifieke referentiemarkup',
    suffix: '%',
    format: v => (v as number) * 100,
  },
  {
    key: 'productWarranty',
    label: 'Productgarantie',
    suffix: 'jaar',
    width: 1 / 2,
    required: category !== ProductCategory.MiscellaneousMain,
  },
  {
    key: 'isPresentedOnQuote',
    label: 'Productdetails tonen op offerte',
    width: 1 / 2,
  },
  {
    key: 'isdeCode',
    label: 'ISDE meldcode',
    width: 1 / 2,
  },
  {
    key: 'brand',
    label: 'Merk',
    width: 1 / 2,
    format: v => formatBrandName((v as any).name as string), // TODO: type correctly
  },
  {
    key: 'series',
    label: 'Serie',
    width: 1 / 2,
  },
  {
    key: 'model',
    label: 'Model',
    width: 1 / 2,
  },
  {
    key: 'unitsPerHour',
    label: 'Gemiddeld installatietempo',
    width: 1 / 2,
    suffix: 'units / uur',
  },
  {
    key: 'vaporPermeability',
    label: 'Dampdoorlatendheid',
    width: 1 / 2,
    format: v => PRODUCT_ENUMS_NL[v],
  },
  {
    key: 'advantages',
    label: 'Voordelen',
    // required: category !== ProductCategory.MiscellaneousMain, // disabled for now for Jelle, this field will be removed/replaced
  },
  {
    key: 'disadvantages',
    label: 'Nadelen',
  },
  {
    key: 'panelAmount',
    label: 'Aantal panelen',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'systemPower',
    label: 'Systeemvermogen',
    width: 1 / 2,
    suffix: 'Wp',
  },
  {
    key: 'weight',
    label: 'Gewicht',
    suffix: 'kg',
    width: 1 / 2,
  },
  {
    key: 'countryOfOrigin',
    label: 'Herkomst',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'releaseDate',
    label: 'Introductiedatum',
    placeholder: 'MM-YYYY',
    width: 1 / 2,
  },
  {
    key: 'color',
    label: 'Kleur',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
  },
  {
    key: 'width',
    label: 'Breedte',
    suffix: 'cm',
    width: 1 / 2,
  },
  {
    key: 'height',
    label: 'Hoogte',
    suffix: 'cm',
    width: 1 / 2,
  },
  {
    key: 'depth',
    label: 'Diepte',
    suffix: 'cm',
    width: 1 / 2,
  },
  {
    key: 'thickness',
    label: 'Dikte',
    suffix: 'mm',
    width: 1 / 2,
    // Only required for labor when it's wall or floor
    required:
      category !== 'labor' || solution === 'wallInsulation' || solution === 'floorInsulation',
    // hint: if above holds: 'Moet hetzelfde staan als bij het gerelateerde isolatiemateriaal'
  },
  // PV system
  {
    key: 'pMax',
    label: category !== ProductCategory.Inverter ? 'Piekvermogen' : 'Max. aansluitingsvermogen',
    suffix: 'Wp',
    width: 1 / 2,
    required: category !== ProductCategory.CompletePvSystem,
  },
  {
    key: 'warrantedPMax',
    label: 'Gegarandeerd piekvermogen',
    suffix: 'Wp',
    width: 1 / 2,
  },
  {
    key: 'cellType',
    label: 'Celtype',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
  },
  {
    key: 'panelType',
    label: 'Paneeltype',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    divider: 2,
  },
  {
    key: 'tier',
    label: 'Klasse',
    format: v => (typeof v === 'string' ? _.upperFirst(v) : v),
    width: 1 / 2,
    divider: 2,
    required: true,
  },
  {
    key: 'backsheetColor',
    label: 'Kleur backsheet',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    required: true,
  },
  {
    key: 'frameColor',
    label: 'Framekleur',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    required: true,
  },
  {
    key: 'strings',
    label: 'Aantal strings',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'maxOutputPower',
    label: 'Uitgangsvermogen',
    width: 1 / 2,
    suffix: 'W',
  },
  {
    key: 'phases',
    label: 'Aantal fasen',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'monitoring',
    label: 'Monitoring',
    width: 1 / 2,
    format: v => PRODUCT_ENUMS_NL[v],
    required: true,
  },
  {
    key: 'extraWarrantyAvailable',
    label: 'Extra garantie beschikbaar',
    width: 1 / 2,
  },
  {
    key: 'youTubeId',
    label: 'YouTube video ID',
    width: 1 / 2,
  },
  {
    key: 'connectionType',
    label: 'Type aansluiting',
    width: 1 / 2,
    format: v => PRODUCT_ENUMS_NL[v],
    required: true,
  },
  {
    key: 'panelId',
    label: 'Bijbehorend paneel',
    prefix: 'ID',
    width: 1 / 2,
    format: id => getProductTitleById(id as string),
    required: true,
  },
  {
    key: 'inverterId',
    label: 'Bijbehorende omvormer',
    prefix: 'ID',
    width: 1 / 2,
    format: id => getProductTitleById(id as string),
    required: true,
  },
  {
    key: 'optimizerId',
    label: 'Bijbehorende optimizer',
    prefix: 'ID',
    width: 1 / 2,
    format: id => getProductTitleById(id as string),
  },
  {
    key: 'installationMaterialId',
    label: 'Bijbehorend installatiemateriaal',
    prefix: 'ID',
    width: 1 / 2,
    format: id => getProductTitleById(id as string),
  },
  {
    key: 'laborId',
    label: 'Bijbehorende arbeid',
    prefix: 'ID',
    width: 1 / 2,
    format: id => getProductTitleById(id as string),
  },
  {
    key: 'materials',
    label: 'Inbegrepen installatiematerialen',
  },
  // Fusebox
  {
    key: 'groups',
    label: 'Aantal groepen',
    width: 1 / 2,
  },
  {
    key: 'residualCurrentDevices',
    label: 'Aantal aardlekschakelaars',
    width: 1 / 2,
  },
  // Insulation materials
  {
    key: 'rd',
    label: 'Rd-waarde',
    suffix: 'm²K/W',
    width: 1 / 2,
    required: true,
  },
  // Glazing
  {
    key: 'u',
    label: 'U-waarde',
    suffix: 'W/m²K',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'zta',
    label: 'ZTA-waarde',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'glassType',
    label: 'Glastype',
    format: v => PRODUCT_ENUMS_NL[v],
    divider: 3,
    required: true,
  },
  {
    key: 'material',
    label: 'Materiaal',
    width: 1 / 2,
    // Only required for labor when it's wall or floor
    required:
      category === 'labor' && (solution === 'wallInsulation' || solution === 'floorInsulation'),
  },
  // roofInsulation
  {
    key: 'paintable',
    label: 'Beschilderbaar',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'placement',
    label: 'Plaatsing',
    width: 1 / 2,
    required: true,
  },
  // Central heating centralHeatingBoiler
  {
    key: 'cwClass',
    label: 'CW-klasse',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'combiBoiler',
    label: 'Combiketel',
    width: 1 / 2,
  },
  {
    key: 'noise',
    label: 'Geluid',
    suffix: 'dB',
    width: 1 / 2,
  },
  {
    key: 'efficiency',
    label: 'Efficiëntie',
    suffix: '%',
    format: v => ((v as number) * 100).toFixed(1),
    width: 1 / 2,
  },
  {
    key: 'energyLabel',
    label: 'Energielabel',
    width: 1 / 2,
  },
  {
    key: 'openThermSupport',
    label: 'Ondersteunt OpenTherm®',
    width: 1 / 2,
  },
  // Thermostat
  {
    key: 'communicationProtocol',
    label: 'Communicatieprotocol',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
  },
  {
    key: 'wireless',
    label: 'Draadloos',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'smartphoneApp',
    label: 'Smartphone app',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'programmable',
    label: 'Programmeerbaar',
    width: 1 / 2,
    required: true,
  },
  // Heat pump
  {
    key: 'heatPumpType',
    label: 'Type pomp',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    divider: 2,
    required: true,
  },
  {
    key: 'splitUnit',
    label: 'Split unit',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    required: solution !== Solution.AirToAirHeatPump,
  },
  {
    key: 'power',
    label: 'Vermogen',
    suffix: 'W',
    width: 1 / 2,
    required: solution !== Solution.HomeBattery,
  },
  {
    key: 'mainsConnection',
    label: 'Aansluitingsspanning',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
  },
  {
    key: 'minConnectionLoad',
    label: 'Minimale aansluitwaarde',
    format: v => PRODUCT_ENUMS_NL[v],
    width: 1 / 2,
    required: true,
  },
  {
    key: 'mainsConnectionCurrentRequired',
    label: 'Benodigde stroomsterkte hoofdaansluiting',
    placeholder: '35',
    suffix: 'A',
    width: 1 / 2,
    required: true, // needed for meterkast verzwaring process
  },
  {
    key: 'minFlowTemperature',
    label: 'Min flow temperatuur',
    suffix: '°C',
    width: 1 / 2,
  },
  {
    key: 'maxFlowTemperature',
    label: 'Max flow temperatuur',
    suffix: '°C',
    width: 1 / 2,
  },
  {
    key: 'copDelta5',
    label: 'COP @ ∆T=5°K',
    width: 1 / 2,
  },
  {
    key: 'copDelta10',
    label: 'COP @ ∆T=10°K',
    width: 1 / 2,
  },
  {
    key: 'heatingOutput735',
    label: 'Warmte-output bij 7/35°C',
    suffix: 'kW',
    width: 1 / 2,
  },
  {
    key: 'heatingOutput755',
    label: 'Warmte-output bij 7/75°C',
    suffix: 'kW',
    width: 1 / 2,
  },
  {
    key: 'electricityConsumption035',
    label: 'Stroomverbruik bij 0/35°C',
    suffix: 'kW',
    width: 1 / 2,
  },
  {
    key: 'electricityConsumption055',
    label: 'Stroomverbruik bij 0/55°C',
    suffix: 'kW',
    width: 1 / 2,
  },
  {
    key: 'capacity',
    label: 'Capaciteit',
    suffix: 'kWh',
    width: 1 / 2,
    required: true,
  },
  {
    key: 'maxOperatingCurrent',
    label: 'Max stroomsterkte',
    suffix: 'A',
    width: 1 / 2,
  },
  {
    key: 'maxElectricityConsumption',
    label: 'Max stroomverbruik',
    suffix: 'kW',
    width: 1 / 2,
  },
  {
    key: 'necessaryFuses',
    label: 'Benodigde zekeringen',
    width: 1 / 2,
  },
  {
    key: 'flowRate',
    label: 'Flow rate',
    suffix: 'm³/h',
    width: 1 / 2,
  },
  {
    key: 'internalUnitNoise',
    label: 'Geluid interne unit',
    suffix: 'dB',
    width: 1 / 2,
  },
  {
    key: 'externalUnitNoise',
    label: 'Geluid externe unit',
    suffix: 'dB',
    width: 1 / 2,
  },
  {
    key: 'integratedBoiler',
    label: 'Geïntegreerde boiler',
    width: 1 / 2,
  },
  {
    key: 'separateBoiler',
    label: 'Losse boiler',
    width: 1 / 2,
  },
  {
    key: 'modular',
    label: 'Modulair',
    width: 1 / 2,
  },
  {
    key: 'boilerCapacity',
    label: 'Boilerinhoud',
    suffix: 'L',
    width: 1 / 2,
  },
  {
    key: 'boilerConnection',
    label: 'Aansluiting ketel',
    width: 1 / 2,
  },
  {
    key: 'thermostatConnection',
    label: 'Aansluiting thermostaat',
    width: 1 / 2,
  },
  {
    key: 'power735',
    label: 'Vermogen bij 7/35°C',
    width: 1 / 2,
    suffix: 'W',
    required: true,
  },
  {
    key: 'power755',
    label: 'Vermogen bij 7/55°C',
    width: 1 / 2,
    suffix: 'W',
    required: true,
  },
  {
    key: 'minOutdoorTemperature',
    label: 'Min buitentemperatuur',
    width: 1 / 2,
    suffix: '°C',
    required: solution !== Solution.AirToAirHeatPump,
  },
  {
    key: 'scop35',
    label: 'SCOP 35°C',
    width: 1 / 2,
    required: solution !== Solution.AirToAirHeatPump,
  },
  {
    key: 'scop55',
    label: 'SCOP 55°C',
    width: 1 / 2,
    required: solution !== Solution.AirToAirHeatPump,
  },
  {
    key: 'heatingSettings',
    label: 'Stookinstellingen',
    width: 1 / 2,
    required: solution !== Solution.AirToAirHeatPump,
  },
  {
    key: 'refrigerant',
    label: 'Koudemiddel',
    width: 1 / 2,
  },
  {
    key: 'refrigerantGWP',
    label: 'Koudemiddel GWP',
    width: 1 / 2,
  },
  {
    key: 'sizeInternalUnit',
    label: 'Grootte interne unit',
    width: 1 / 2,
    suffix: 'L×B×H (cm)',
  },
  {
    key: 'sizeExternalUnit',
    label: 'Grootte externe unit',
    width: 1 / 2,
    suffix: 'L×B×H (cm)',
  },
  {
    key: 'weightExternalUnit',
    label: 'Gewicht externe unit',
    width: 1 / 2,
    suffix: 'kg',
  },
  {
    key: 'subsidy',
    label: 'Subsidie',
    format: v => delimit(v as number, 0),
    width: 1 / 2,
    prefix: '€',
  },
];

export default productSpecs;
