import { gql, useMutation } from '@apollo/client';
import { useForm } from '@energiebespaarders/hooks';
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Modal,
  RadioGroup,
  Select,
  Textarea,
} from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { Information } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { isProduction } from '../../config';
import { SEND_INTERNAL_ANNOUNCEMENT } from '../../domains/Communications/queries';
import useToaster from '../../hooks/useToaster';
import {
  sendInternalAnnouncement,
  sendInternalAnnouncementVariables,
} from '../../types/generated/sendInternalAnnouncement';
import {
  updateInstallerStatus,
  updateInstallerStatusVariables,
} from '../../types/generated/updateInstallerStatus';
import { InstallerStatus, InstallerStatusReason } from '../../types/graphql-global-types';
import { ClickableBox } from './InstallerInfo';
import InstallerStatusIndicators from './InstallerStatusIndicator';
import InstallerStatusInfo from './InstallerStatusInfo';
import {
  updateInstallerLeadBuyerStatus,
  updateInstallerLeadBuyerStatusVariables,
} from '../../types/generated/updateInstallerLeadBuyerStatus';

export const installerStatusReasonNL: Record<InstallerStatusReason, string> = {
  invalidPrices: 'Prijzen kloppen niet',
  noCapacity: 'Geen capaciteit',
  collaborationStopped: 'Samenwerking gestopt',
  other: 'Overig',
};

const UPDATE_INSTALLER_STATUS = gql`
  mutation updateInstallerStatus($id: ID!, $status: InstallerStatusUpdateInput!) {
    updateInstallerStatus(id: $id, status: $status) {
      id
      status {
        value
        reason
        comment
        date
      }
    }
  }
`;

const UPDATE_INSTALLER_LEADBUYER_STATUS = gql`
  mutation updateInstallerLeadBuyerStatus($id: ID!, $status: InstallerStatusUpdateInput!) {
    updateInstallerLeadBuyerStatus(id: $id, status: $status) {
      id
      leadBuyerStatus {
        value
        reason
        comment
        date
      }
    }
  }
`;

type InstallerStatusFormData = {
  status: InstallerStatus;
  reason: InstallerStatusReason;
  comment: string;

  sendEmail: boolean;
};

type InstallerStatusConfirmationModalProps = {
  installerId: string;
  installerName: string;
  initialStatus: InstallerStatus;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  isLeadGen?: boolean;
};

const InstallerStatusOptions: DropdownOption<InstallerStatus>[] = Object.entries(
  InstallerStatusIndicators,
).map(([value, indicator]) => ({
  label: (
    <span>
      <Icon icon={indicator.icon} solid fill={indicator.color} /> {indicator.label}{' '}
      {indicator.description ? `(${indicator.description})` : ''}
    </span>
  ),
  value: value as InstallerStatus,
}));

const InstallerStatusReasonOptions: DropdownOption<InstallerStatusReason>[] = Object.entries(
  installerStatusReasonNL,
).map(([value, label]) => ({
  label,
  value: value as InstallerStatusReason,
}));

const InstallerStatusConfirmationModal: React.FC<InstallerStatusConfirmationModalProps> = ({
  installerId,
  installerName,
  initialStatus,
  isOpen,
  setIsOpen,
  onClose,
  isLeadGen = false,
}) => {
  const toast = useToaster();
  const [isShowingStatusInfo, setShowingStatusInfo] = useState(false);

  const [updateInstallerStatus, { loading, error }] = useMutation<
    updateInstallerStatus,
    updateInstallerStatusVariables
  >(UPDATE_INSTALLER_STATUS, {
    onCompleted: onClose,
  });

  const [
    updateInstallerLeadBuyerStatus,
    { loading: loadingLeadGen, error: errorLeadGen },
  ] = useMutation<updateInstallerLeadBuyerStatus, updateInstallerLeadBuyerStatusVariables>(
    UPDATE_INSTALLER_LEADBUYER_STATUS,
    {
      onCompleted: onClose,
    },
  );

  const [sendInternalAnnouncement] = useMutation<
    sendInternalAnnouncement,
    sendInternalAnnouncementVariables
  >(SEND_INTERNAL_ANNOUNCEMENT, {
    onCompleted: () => toast({ type: 'success', message: 'E-mail succesvol verzonden!' }),
    onError: () => toast({ type: 'error', message: 'E-mail kon niet worden verzonden!' }),
  });

  const { formState, submitForm, handleChange } = useForm<InstallerStatusFormData>({
    initialValues: { status: initialStatus, sendEmail: true, comment: '', reason: '' },
    handleSubmit(values) {
      if (isLeadGen) {
        updateInstallerLeadBuyerStatus({
          variables: {
            id: installerId,
            status: {
              value: values.status,
              reason: values.reason || undefined,
              comment: values.comment || undefined,
            },
          },
        });
      } else {
        updateInstallerStatus({
          variables: {
            id: installerId,
            status: {
              value: values.status,
              reason: values.reason || undefined,
              comment: values.comment || undefined,
            },
          },
        });
      }
      if (values.sendEmail) {
        const statusNL = InstallerStatusIndicators[values.status].label;
        const body = `Per ${dayjs().format('dddd D MMMM')} staat ${installerName} op ${
          InstallerStatusIndicators[values.status].label
        }.${values.reason ? `\nReden: ${installerStatusReasonNL[values.reason]}` : ''}${
          values.comment ? `\nToelichting: ${values.comment}` : ''
        }`;
        sendInternalAnnouncement({
          variables: {
            subject: `${installerName} staat nu op ${statusNL}`,
            body,
            to: isProduction
              ? 'ip-updates@energiebespaarders.nl'
              : 'devs+ip-updates@energiebespaarders.nl',
          },
        });
      }
    },
    validate(values, errors) {
      if (values.status !== InstallerStatus.active && !values.reason) {
        // For the Active status, a reason is not needed
        errors.reason = 'Verplicht';
      }
      if (values.status === InstallerStatus.inactive && !values.comment) {
        // For Inactive, which is quite drastic, a comment is required
        errors.comment = 'Verplicht';
      }
      return errors;
    },
    blockEnterToSubmit: true,
  });

  return (
    <Modal
      title={`${isLeadGen ? 'Lead Koper Status' : 'Status'} wijzigen van ${installerName}`}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      buttons={[
        {
          label: 'Annuleren',
          bgColor: 'red',
          onClick: () => setIsOpen(false),
        },
        {
          label: 'Bevestigen',
          onClick: () => submitForm(),
          disabled: formState.status.value === initialStatus,
          loading: loading || loadingLeadGen,
          error: error || errorLeadGen,
        },
      ]}
    >
      <Flex flexWrap="wrap">
        <Box width={[1 / 2, 2 / 3, 0.8]}>
          <Select<InstallerStatus>
            label="Status"
            options={InstallerStatusOptions}
            onChange={o => handleChange({ status: o.value })}
            value={InstallerStatusOptions.find(o => o.value === formState.status.value)}
            error={formState.status.error}
            touched={formState.status.touched}
          />
        </Box>

        <ClickableBox
          width={[1 / 2, 1 / 3, 0.2]}
          mt={5}
          pl={2}
          onClick={() => setShowingStatusInfo(!isShowingStatusInfo)}
        >
          <Icon icon={Information} solid fill="blue" hoverColor="blueDark" mr={1} />
          Info
        </ClickableBox>

        {isShowingStatusInfo && <InstallerStatusInfo />}

        <Box width={1}>
          <Select<InstallerStatusReason>
            label="Reden"
            options={InstallerStatusReasonOptions}
            onChange={o => handleChange({ reason: o.value })}
            value={InstallerStatusReasonOptions.find(o => o?.value === formState.reason?.value)}
            error={formState.reason?.error}
            touched={formState.reason?.touched}
          />

          <Textarea
            value={formState.comment?.value || ''}
            onChange={e => handleChange({ comment: e.target.value })}
            label="Toelichting"
            width="100%"
            autoFocus
            minHeight="100px"
            error={formState.comment?.error}
            touched={formState.comment?.touched}
          />

          <RadioGroup.Label>
            E-mail sturen naar{' '}
            <a
              href="https://groups.google.com/a/energiebespaarders.nl/g/ip-updates"
              target="_blank"
              rel="noreferrer"
            >
              IP-updates email groep
            </a>
            {isProduction ? '' : ' (alleen in productie)'}
          </RadioGroup.Label>
          <RadioGroup
            error={formState.sendEmail?.error}
            touched={formState.sendEmail?.touched}
            onChange={() => handleChange({ sendEmail: !formState.sendEmail.value })}
          >
            <Checkbox
              label="Interne e-mail versturen"
              value={true}
              checked={formState.sendEmail.value}
              id="ip-status-change"
              disabled={initialStatus === formState.status.value}
            />
          </RadioGroup>
        </Box>
      </Flex>
    </Modal>
  );
};

export default InstallerStatusConfirmationModal;
