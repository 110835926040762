import { useMutation } from '@apollo/client';
import { Box, Flex, Icon, Select, Tooltip } from '@energiebespaarders/symbols';
import { Blue, Green, Medium, Orange, Red, Yellow } from '@energiebespaarders/symbols/helpers';
import { Edit } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useMemo, useState } from 'react';
import useToaster from '../../../hooks/useToaster';
import { dealsByHouseSolutionDomain_dealsByHouseSolutionDomain } from '../../../types/generated/dealsByHouseSolutionDomain';
import {
  updateDealSalesManager,
  updateDealSalesManagerVariables,
} from '../../../types/generated/updateDealSalesManager';
import { DealStatus } from '../../../types/graphql-global-types';
import { MarkDealLostModal } from '../../modals';
import { EditDealMetadataModal } from '../../modals/EditDealMetadataModal';
import ReopenDealModal from './ReopenModal';
import SalesManagerPicker, { UPDATE_SALES_MANAGER } from './SalesManagerPicker';
import SetDealOnHoldModal from './SetOnHoldModal';

type Option = {
  value: DealStatus;
  label: JSX.Element;
  callback: () => void;
  disabled: boolean;
};

interface DealStatusControlProps {
  deal: dealsByHouseSolutionDomain_dealsByHouseSolutionDomain;
}

const DealControl: React.FC<DealStatusControlProps> = ({ deal }) => {
  const toast = useToaster();
  const status = deal.status;
  const [losingDeal, setLosingDeal] = useState(false);
  const [onHoldingDeal, setOnHoldingDeal] = useState(false);
  const [reopeningDeal, setReopeningDeal] = useState(false);
  const [editingDealMetaData, setEditingDealMetaData] = useState(false);

  const [updateSalesManager, { loading: updateSalesManagerLoading }] = useMutation<
    updateDealSalesManager,
    updateDealSalesManagerVariables
  >(UPDATE_SALES_MANAGER, {
    onError(error) {
      toast({ type: 'error', message: error.message });
    },
    onCompleted() {
      toast({ type: 'success', message: 'Sales manager gewijzigd' });
    },
  });

  const statusOptions: Option[] = useMemo(() => {
    if (deal.isInstalled) {
      return [
        {
          value: DealStatus.accepted,
          label: <Green>Uitgevoerd</Green>,
          callback: () => console.log('Niks te doen'),
          disabled: true,
        },
      ];
    }
    const optionButtons = [
      {
        value: DealStatus.onHold,
        label: <Yellow>On Hold</Yellow>,
        callback: () => setOnHoldingDeal(true),
        disabled: false,
      },
      {
        value: DealStatus.lost,
        label: <Red>Verloren{deal.activeQuote?.isAccepted ? ' na akkoord' : ' voor akkoord'}</Red>,
        callback: () => setLosingDeal(true),
        disabled: false,
      },
    ];
    if (deal.activeQuote?.isAccepted && !deal.activeQuote.isCancelled) {
      optionButtons.unshift({
        value: DealStatus.accepted,
        label: <Green>Akkoord</Green>,
        callback: () => setReopeningDeal(true),
        disabled: false,
      });
    } else if (deal.activeQuote?.isExpired) {
      optionButtons.unshift({
        value: DealStatus.expired,
        label: (
          <Blue>
            Open <Orange>(maar verlopen)</Orange>
          </Blue>
        ),
        callback: () => setReopeningDeal(true),
        disabled: false,
      });
    } else {
      optionButtons.unshift({
        value: DealStatus.open,
        label: <Blue>Open</Blue>,
        callback: () => setReopeningDeal(true),
        disabled: false,
      });
    }
    return optionButtons;
  }, [deal]);

  const handleChange = useCallback(
    (option: Option) => {
      if (option.value !== status) {
        option.callback();
      }
    },
    [status],
  );

  const selectedStatusOption = statusOptions.find(o => o.value === status);

  if (!selectedStatusOption) return <p>No option for status {status}</p>;

  return (
    <>
      {deal.title ? <p>Title: {deal.title}</p> : ''}
      {deal.description ? <p>Beschrijving: {deal.description}</p> : ''}
      {deal.icon ? <p>Icon: {deal.icon}</p> : ''}
      Status: <Medium>{selectedStatusOption?.label || 'onbekend'}</Medium>
      <Flex justifyContent="flex-end">
        <Tooltip content="Deal details aanpassen" bgColor="orange">
          <Icon
            icon={Edit}
            solid
            hoverColor="orange"
            onClick={() => setEditingDealMetaData(true)}
          />
        </Tooltip>
      </Flex>
      <Flex m={-1}>
        <Box width={1 / 2} p={1}>
          <Select<DealStatus>
            highlightColor="greenSlate"
            clearable={false}
            label="Status van de uitvoerdeal wijzigen:"
            onChange={e => handleChange(e as Option)}
            options={statusOptions}
            value={selectedStatusOption}
            disabled={selectedStatusOption.disabled}
          />
        </Box>
        <Box width={1 / 2} p={1}>
          <SalesManagerPicker
            disabled={updateSalesManagerLoading}
            salesManagerId={deal.salesManagerId}
            setSalesManagerId={salesManagerId => {
              updateSalesManager({
                variables: {
                  dealId: deal.id,
                  deal: { salesManagerId },
                },
              });
            }}
          />
        </Box>
      </Flex>
      {losingDeal && <MarkDealLostModal deal={deal} closeModal={() => setLosingDeal(false)} />}
      {onHoldingDeal && (
        <SetDealOnHoldModal deal={deal} closeModal={() => setOnHoldingDeal(false)} />
      )}
      {reopeningDeal && <ReopenDealModal deal={deal} closeModal={() => setReopeningDeal(false)} />}
      {editingDealMetaData && (
        <EditDealMetadataModal
          closeModal={() => setEditingDealMetaData(false)}
          dealId={deal.id}
          title={deal.title}
          description={deal.description}
          icon={deal.icon}
          priority={deal.priority}
        />
      )}
    </>
  );
};

export default DealControl;
