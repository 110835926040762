import { useQuery } from '@apollo/client';
import { PRODUCT_ENUMS_NL, Solution } from '@energiebespaarders/constants';
import { useForm } from '@energiebespaarders/hooks';
import { DeepPartial, FormValueObject, isMissing } from '@energiebespaarders/hooks/useForm';
import {
  Box,
  Button,
  Flex,
  Input,
  Placeholder,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  Tooltip,
} from '@energiebespaarders/symbols';
import { Red, Small } from '@energiebespaarders/symbols/helpers';
import { AddCircle, MinusCircle } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, useMemo } from 'react';
import { GET_BRANDS } from '../../domains/Brands';
import useToaster from '../../hooks/useToaster';
import { MATERIAL_OPTIONS } from '../../lib/dataMaps/materialDropdownData';
import productSpecs, { InputFieldAttributes, ProductSpecObj } from '../../lib/dataMaps/productData';
import { GET_SCHEMA } from '../../queries/products';
import { Writable } from '../../typeHelpers';
import { getBrands } from '../../types/generated/getBrands';
import {
  getSchemaProducts,
  getSchemaProductsVariables,
  getSchemaProducts___type_fields,
} from '../../types/generated/getSchemaProducts';
import { productById_productById } from '../../types/generated/productById';
import { ProductCategory, ProductInput } from '../../types/graphql-global-types';
import ArrayInput from '../ArrayInput';
import ISDECodeDropdown from './isde/ISDECodeDropdown';
import YouTubeInput from './YouTubeInput';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';

interface Props {
  handleFormSubmit: (product: ProductInput) => any;
  hiddenFields: string[];
  schemaName: string;
  product?: productById_productById;
  category: ProductCategory;
  solution: Solution;
}

type allowedProductKeys = ProductInput & { brand: string };

// Needed to determine which fields of the Product type in the schema can be used in the ProductInput
// Basically just the ProductInput type, but accessible at runtime
const productInputKeys: Record<keyof allowedProductKeys, boolean> = {
  advantages: true,
  archived: true,
  backsheet: true,
  backsheetColor: true,
  brand: true,
  brandId: true,
  category: true,
  cellType: true,
  color: true,
  combiBoiler: true,
  communicationProtocol: true,
  connectionType: true,
  countryOfOrigin: true,
  customMarkup: true,
  cwClass: true,
  depth: true,
  description: true,
  disadvantages: true,
  efficiency: true,
  energyLabel: true,
  externalUnitNoise: true,
  extraWarrantyAvailable: true,
  flowRate: true,
  frameColor: true,
  glassType: true,
  groups: true,
  heatingSettings: true,
  heatingSystemType: true,
  heatPumpType: true,
  height: true,
  installationMaterialId: true,
  internalDescription: true,
  inverterId: true,
  isdeCode: true,
  isPresentedOnQuote: true,
  laborId: true,
  mainsConnectionCurrentRequired: true,
  material: true,
  materials: true,
  maxElectricityConsumption: true,
  maxFlowTemperature: true,
  maxOperatingCurrent: true,
  maxOutputPower: true,
  minConnectionLoad: true,
  minOutdoorTemperature: true,
  model: true,
  monitoring: true,
  necessaryFuses: true,
  noise: true,
  openThermSupport: true,
  optimizerId: true,
  paintable: true,
  panelAmount: true,
  panelId: true,
  panelType: true,
  phases: true,
  placement: true,
  pMax: true,
  power: true,
  priceUnit: true,
  productWarranty: true,
  programmable: true,
  rd: true,
  refrigerant: true,
  refrigerantGWP: true,
  releaseDate: true,
  residualCurrentDevices: true,
  scop35: true,
  scop55: true,
  series: true,
  sizeExternalUnit: true,
  sizeInternalUnit: true,
  smartphoneApp: true,
  solution: true,
  splitUnit: true,
  strings: true,
  subsidy: true,
  tax: true,
  thickness: true,
  tier: true,
  title: true,
  u: true,
  unitsPerHour: true,
  vaporPermeability: true,
  warrantedPMax: true,
  weight: true,
  weightExternalUnit: true,
  width: true,
  wireless: true,
  youTubeId: true,
  zta: true,
  capacity: true,
};

const LabelWithOptionalToolTip: React.FC<{
  label: string;
  required: boolean;
  tooltip?: string;
}> = ({ label, required, tooltip }) => {
  const formattedLabel = `${required ? '* ' : ''}${label}`;
  return (
    <>
      {tooltip ? (
        <Tooltip content={tooltip}>
          <>{formattedLabel} (?)</>
        </Tooltip>
      ) : (
        formattedLabel
      )}
    </>
  );
};

type Scalar = 'String' | 'Float' | 'Int' | 'ID';

interface ScalarInputProps extends InputFieldAttributes {
  type: string;
  pattern?: string;
}

// Convert GraphQL scalar type to input type
const scalarInputProps: Record<Scalar, ScalarInputProps> = {
  String: { type: 'text' },
  Float: { type: 'number' },
  Int: { type: 'number', pattern: '[0-9]*' },
  ID: { type: 'text' },
};

const assembleScalarInputProps = (fieldtype: Scalar, spec: ProductSpecObj): ScalarInputProps => {
  return { ...scalarInputProps[fieldtype], ...(spec.rangeOptions ? spec.rangeOptions : null) };
};

interface BrandDropDownOption {
  label: string;
  value: string;
}

const convertNullValuesToString = (values: ProductInput) => {
  let obj = {};

  for (const key in values) {
    obj = {
      ...obj,
      [key]: values[key as keyof ProductInput] ?? '',
    };
  }

  return obj;
};

const renderInputField = (
  field: getSchemaProducts___type_fields,
  fieldValue: any,
  requiredInSchema = false,
  handleChange: (e: DeepPartial<FormValueObject<any>>) => void,
  brandOptions: BrandDropDownOption[],
  category: ProductCategory,
  solution: Solution,
) => {
  const spec = productSpecs(category, solution).find(spec => spec.key === field.name)!;
  if (!spec) throw new Error(`No spec for ${field.name}!`);
  const tooltip = spec.tooltip;
  const { prefix, suffix, width, divider, placeholder } = spec || {};
  const label = spec ? spec.label : field.name;
  const fieldType = field.type;
  const { value, error, touched } = fieldValue;
  const requiredInSpec = !!spec.required;

  if (field.name === 'brand') {
    return (
      <Box key="brands" width={1 / 2} px={1}>
        <Select<string>
          clearable
          label={
            <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
          }
          name="brandId"
          options={brandOptions}
          onChange={e => handleChange({ brandId: e?.value || '' })}
          value={brandOptions.find(opt => opt.value === value)}
          touched={touched}
          error={error}
        />
      </Box>
    );
  } else if (field.name === 'isdeCode') {
    return (
      <Box key="isdeCode" width={1 / 2} px={1}>
        <ISDECodeDropdown
          solution={solution}
          onChange={v => handleChange({ isdeCode: v || '' })}
          creatable
          value={value}
          touched={touched}
          error={error}
        />
      </Box>
    );
  } else if (field.name === 'youTubeId') {
    return (
      <Box key="youTubeId" width={1 / 2} px={1}>
        <YouTubeInput
          onChange={v => handleChange({ youTubeId: v || '' })}
          value={value}
          touched={touched}
          error={error}
        />
      </Box>
    );
  }

  if (field.name === 'description' || field.name === 'internalDescription') {
    return (
      <Box key={field.name} width={1} px={1}>
        <Textarea
          defaultValue={value}
          label={
            <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
          }
          minHeight="5em"
          onChange={e => handleChange({ [field.name]: e.target.value })}
          name={field.name}
          error={error}
          touched={touched}
          {...assembleScalarInputProps(fieldType.name as Scalar, spec)}
        />
      </Box>
    );
  }

  // Map the GraphQL type to input type
  if (fieldType.kind === 'SCALAR' || (requiredInSchema && fieldType.ofType!.kind === 'SCALAR')) {
    if (field.name === 'material') {
      return (
        <Box width={width || 1} key={field.name} px={1}>
          <Select
            name={field.name}
            label={
              <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
            }
            onChange={e => handleChange({ [field.name]: e.value })}
            options={MATERIAL_OPTIONS}
            value={MATERIAL_OPTIONS.find(option => option.value === value) || ''}
            touched={touched}
            error={error}
            {...assembleScalarInputProps(fieldType.name as Scalar, spec)}
          />
        </Box>
      );
    }

    if (
      fieldType.name === 'Boolean' ||
      (requiredInSchema && fieldType.ofType?.name === 'Boolean')
    ) {
      return (
        <Box width={width || 1} key={field.name} px={1}>
          <RadioGroup
            error={error}
            touched={touched}
            label={
              <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
            }
            onChange={value => handleChange({ [field.name]: value })}
          >
            <Radio id={`${field.name}-false`} checked={value === false} label="Nee" value={false} />
            <Radio id={`${field.name}-true`} checked={value === true} label="Ja" value={true} />
          </RadioGroup>
        </Box>
      );
    }

    return (
      <Box width={width || 1} key={field.name} px={1}>
        <Input
          addonSide={prefix ? 'start' : suffix ? 'end' : undefined}
          addonContent={prefix || suffix || ''}
          name={field.name}
          label={
            <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
          }
          placeholder={placeholder || label}
          onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;

            const formattedValue =
              value === ''
                ? ''
                : fieldType.name === 'Int' || fieldType.name === 'Float'
                ? Number(value)
                : value;

            handleChange({ [field.name]: formattedValue });
          }}
          value={value ?? ''}
          error={error}
          touched={touched}
          {...assembleScalarInputProps(fieldType.name as Scalar, spec)}
        />
      </Box>
    );
  } else if (fieldType.kind === 'ENUM' || (requiredInSchema && fieldType.ofType!.kind === 'ENUM')) {
    const nullableEnumValues = fieldType.enumValues || fieldType.ofType?.enumValues || [];
    // Render a dropdown if there are more than 4 options
    if (nullableEnumValues.length > 4) {
      const options: DropdownOption[] = [
        { value: '', label: `Kies ${(label || '____').toLowerCase()}`, disabled: true },
      ];

      const defaultOptions = nullableEnumValues.map(option => ({
        value: option.name,
        label: PRODUCT_ENUMS_NL[option.name] || option.name,
        disabled: false,
      }));
      const customOptions =
        spec.constructOptions?.(nullableEnumValues, field, solution) || defaultOptions;
      options.push(...(customOptions || defaultOptions));

      return (
        <Box width={width || 1} key={field.name} px={1}>
          <Select<string>
            name={field.name}
            label={
              <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
            }
            onChange={e => handleChange({ [field.name]: e.value })}
            options={options}
            value={options.find(option => option.value === value) || ''}
            touched={touched}
            error={error}
            {...assembleScalarInputProps(fieldType.name as Scalar, spec)}
          />
        </Box>
      );
    } else {
      // Otherwise just render up to 4 radio options
      return (
        <Box width={width || 1} key={field.name} px={1}>
          <RadioGroup
            divider={divider || 2}
            label={
              <LabelWithOptionalToolTip label={label} tooltip={tooltip} required={requiredInSpec} />
            }
            onChange={value => handleChange({ [field.name]: value })}
            error={error}
            touched={touched}
          >
            {nullableEnumValues.map((option, index: number) => (
              <Radio
                key={`${field.name}-${index}`}
                id={`${field.name}-${index}`}
                checked={Boolean(value === option.name)}
                label={(spec.format?.(option.name) as string) || PRODUCT_ENUMS_NL[option.name]}
                value={option.name}
              />
            ))}
          </RadioGroup>
        </Box>
      );
    }
  } else if (fieldType.kind === 'LIST' || (requiredInSchema && fieldType.ofType!.kind === 'LIST')) {
    let listLabel, buttonLabel, itemPlaceholder, addonIcon, iconColor;
    switch (field.name) {
      case 'advantages':
        listLabel = 'Voordelen';
        itemPlaceholder = 'Nieuw voordeel';
        buttonLabel = 'Voordeel toevoegen';
        addonIcon = AddCircle;
        iconColor = 'green';
        break;
      case 'disadvantages':
        listLabel = 'Nadelen';
        itemPlaceholder = 'Nieuw nadeel';
        buttonLabel = 'Nadeel toevoegen';
        addonIcon = MinusCircle;
        iconColor = 'red';
        break;
      case 'materials':
        listLabel = 'Inbegrepen installatiematerialen';
        itemPlaceholder = 'Nieuw installatiemateriaal';
        buttonLabel = 'Materiaal toevoegen';
        addonIcon = AddCircle;
        iconColor = 'green';
        break;
      default:
        listLabel = 'Voordelen';
    }

    return (
      <Box width={width || 1} key={field.name} px={1}>
        <ArrayInput
          array={value}
          addonIcon={addonIcon}
          buttonText={buttonLabel}
          handleChange={(value: any) => handleChange({ [field.name]: value })}
          iconColor={iconColor}
          label={
            <LabelWithOptionalToolTip
              label={listLabel}
              tooltip={tooltip}
              required={requiredInSpec}
            />
          }
          name={field.name}
          placeholder={itemPlaceholder || listLabel}
          error={error}
          touched={touched}
        />
      </Box>
    );
  }
  // TODO: Nested objects?
  return <React.Fragment key={field.name}></React.Fragment>;
};

const removeUnchangedFields = (
  submittedValues: ProductInput,
  initialValues: ProductInput,
  schemaFields?: getSchemaProducts___type_fields[],
) => {
  const res = { ...submittedValues };

  for (const key in res) {
    const typedKey = key as keyof ProductInput;

    // Remove fields that havent been changed
    if (res[typedKey] === initialValues[typedKey]) {
      delete res[typedKey];
    }

    // If an int value has been removed and is now an empty string, convert it to null
    const schemaField = schemaFields?.find(f => f.name === typedKey);
    const fieldIsScalar =
      schemaField?.type?.ofType?.kind === 'SCALAR' || schemaField?.type?.kind === 'SCALAR';

    if (res[typedKey] === '' && fieldIsScalar) {
      res[typedKey] = null;
    }
  }

  return res;
};

/**
 * Removes hidden fields
 * Multiplies percentage fields by 100 to convert from 0-1 to 0-100 range
 * Re formats brand to be brandId
 **/
export function removeUnnecessaryFieldsAndFormat(values: any, hiddenFields: string[]) {
  const res = { ...values };

  Object.keys(values).forEach(key => {
    const spec = productSpecs(values.category).find(spec => spec.key === key);

    if (
      !Object.keys(productInputKeys).includes(
        (key as keyof ProductInput) || hiddenFields.includes(key),
      )
    ) {
      delete res[key];
    }

    if (spec?.suffix === '%') {
      res[key] = typeof res[key] === 'number' ? res[key] * 100 : res[key];
    }
  });

  // We fetch brand object on product, but need to submit it as brandId in the form
  res.brandId = res.brand?.id;
  delete res.brand;

  return res;
}

const createInitialValuesFromProduct = (product: ProductInput, hiddenFields: string[]) => {
  const initialProduct = removeUnnecessaryFieldsAndFormat(product, hiddenFields);
  const formattedProduct = convertNullValuesToString(initialProduct);

  return formattedProduct;
};

const createInitialValueFromSchemaType = (field: getSchemaProducts___type_fields) => {
  const fieldType = field.type;
  const required = field.type.kind === 'NON_NULL';

  if (fieldType.name === 'Boolean' || (required && field.type.ofType?.name === 'Boolean')) {
    return false;
  }

  if (fieldType.kind === 'LIST' || (required && field.type.ofType!.kind === 'LIST')) {
    return [];
  }

  return '';
};

const createInitialValuesFromSchema = (
  schemaFields: getSchemaProducts___type_fields[] | undefined,
) => {
  let obj: Writable<ProductInput> = {};

  schemaFields?.forEach(f => {
    const fieldName = f.name === 'brand' ? 'brandId' : f.name;
    obj = {
      ...obj,
      [fieldName]: createInitialValueFromSchemaType(f),
    };
  });

  return obj;
};

const ProductForm: React.FC<Props & { schemaData: getSchemaProducts | undefined }> = ({
  hiddenFields,
  product,
  handleFormSubmit,
  category,
  solution,
  schemaData,
}) => {
  const { data: brandsData } = useQuery<getBrands>(GET_BRANDS);

  const toast = useToaster();

  const brands = brandsData?.brands;
  const schemaFields = schemaData?.__type!.fields!.filter(
    v =>
      !hiddenFields.includes(v.name) &&
      Object.keys(productInputKeys).includes(v.name as keyof ProductInput),
  );

  const initialValues = useMemo(
    () =>
      product
        ? createInitialValuesFromProduct(product, hiddenFields)
        : createInitialValuesFromSchema(schemaFields),
    [hiddenFields, product, schemaFields],
  );

  // TODO: include brand logo in label field
  const brandOptions = useMemo(
    () =>
      [...(brands || [])]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(b => ({
          label: b.name,
          value: b.id,
        })),
    [brands],
  );

  const { formState, handleChange, submitForm, formHasErrors } = useForm<Writable<ProductInput>>({
    initialValues,
    validate: (values, errors) => {
      const dynamicFieldErrors = (errors as unknown) as Record<keyof ProductInput, string>;

      for (const key in values) {
        const typedKey = key as keyof ProductInput;
        const spec = productSpecs(category, solution).find(spec => spec.key === key);
        const hasCustomValidation = spec?.validate !== undefined;

        // if field isn't empty and has a custom validation function
        const value = values[typedKey];
        if (hasCustomValidation && value?.toString.length) {
          dynamicFieldErrors[typedKey] = spec.validate!(value, product);
          continue;
        }

        // TODO: remove Array check, will be built into isMissing soon
        if (spec?.required && (isMissing(value) || (Array.isArray(value) && value.length === 0))) {
          dynamicFieldErrors[typedKey] = 'Verplicht';
          continue;
        }
      }

      return (dynamicFieldErrors as unknown) as typeof errors;
    },
    handleSubmit: values => {
      const submittableFields = removeUnchangedFields(values, initialValues, schemaFields);
      handleFormSubmit(submittableFields);
    },
  });

  return (
    <Box>
      <Flex flexWrap="wrap" mb={2}>
        {schemaFields?.map(field => {
          /**
           * The Schema data retrieves the brand as a resolved 'brand' field
           * but the useForm gets its fields from the convertInitialValues fn where brand comes in as 'brandId'
           * formattedBrandName converts the field so that it can set the value correctly
           */
          const formattedBrandName = field.name === 'brand' ? 'brandId' : '';
          const required = field.type.kind === 'NON_NULL';

          // if an optional field exists in the schema but isn't saved on the product itself don't return it
          if (!formState[(formattedBrandName || field.name) as keyof ProductInput]) {
            console.error(
              `Field with name ${field.name} on ${product?.__typename} exists in schema but not in formState`,
            );
            toast({
              type: 'error',
              message: `Field with name ${field.name} on ${product?.__typename} exists in schema but not in formState. Tell the devs`,
            });

            return null;
          }

          return renderInputField(
            field,
            formState[(formattedBrandName || field.name) as keyof ProductInput],
            required,
            handleChange,
            brandOptions,
            category,
            solution,
          );
        })}
      </Flex>
      <Box width={1} px={1}>
        {formHasErrors && (
          <Red>
            <Small>Niet alle benodigde velden zijn (correct) ingevuld</Small>
          </Red>
        )}
        <Button fluid label="Opslaan" onClick={submitForm as () => void} />
      </Box>
    </Box>
  );
};

const ProductFormWrapper: React.FC<Props> = props => {
  const { data: schemaData, loading, error } = useQuery<
    getSchemaProducts,
    getSchemaProductsVariables
  >(GET_SCHEMA, {
    variables: { name: props.schemaName },
    skip: !props.schemaName,
    nextFetchPolicy: 'network-only',
  });

  if (error || !schemaData) {
    return <Placeholder error={error} />;
  }

  // The wrapper is needed for re-initializing the form state (initial values) when the product category (schema name) changes
  return !loading ? <ProductForm {...props} schemaData={schemaData} /> : <></>;
};

export default ProductFormWrapper;
