import { useMutation } from '@apollo/client';
import {
  Box,
  Flex,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Toast,
} from '@energiebespaarders/symbols';
import { Gray, Medium, Small } from '@energiebespaarders/symbols/helpers';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlag } from 'react-unleash-flags';
import { useMe } from '../../hooks/useMe';
import useToaster from '../../hooks/useToaster';
import { MANUAL_JOURNEY_DATA } from '../../lib/constants';
import {
  ACCEPT_BARE_BONES_QUOTE,
  ACCEPT_D2C_QUOTE,
  ACCEPT_QUOTE,
  ACCEPT_QUOTE_SUBJECT_TO_FUNDING,
} from '../../queries/installatron';
import { acceptD2CQuote, acceptD2CQuoteVariables } from '../../types/generated/acceptD2CQuote';
import { acceptQuote, acceptQuoteVariables } from '../../types/generated/acceptQuote';
import {
  acceptQuoteBareBones,
  acceptQuoteBareBonesVariables,
} from '../../types/generated/acceptQuoteBareBones';
import {
  acceptSubjectToFunding,
  acceptSubjectToFundingVariables,
} from '../../types/generated/acceptSubjectToFunding';
import { quoteModalQuote_quoteById } from '../../types/generated/quoteModalQuote';
import { QuoteExperiment } from '../../types/graphql-global-types';

type AcceptQuoteModalProps = {
  quote: quoteModalQuote_quoteById;
  closeModal: () => void;
  isOpen?: boolean;
  mobile?: boolean;
  src?: string;
  alt?: string;
  isGVAHouse: boolean;
};

const AcceptQuoteModal: React.FC<AcceptQuoteModalProps> = ({
  quote,
  closeModal,
  isOpen,
  mobile,
  isGVAHouse,
}) => {
  const toast = useToaster();
  const { me } = useMe();
  const now = useMemo(() => dayjs(new Date()).format('YYYY-MM-DD'), []);
  const [acceptType, setAcceptType] = useState('');
  const [dateFundingExpected, setDateFundingExpected] = useState(now);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [subjectToFunding, setSubjectToFunding] = useState(false);
  const isD2CQuote = quote.experiments.includes(QuoteExperiment.d2cOperational);

  const acceptQuoteBareBonesFlag = useFlag('accept-quote-bare-bones');

  const options = useMemo(
    () => [
      { value: '', label: 'Kies een optie', disabled: true },
      { value: 'inPerson', label: 'Thuis' },
      { value: 'phone', label: 'Telefonisch' },
      { value: 'email', label: 'Via de mail' },
      { value: 'jobMutation', label: 'Opdrachtwijziging' }, // used internally by NS, temporary entry until the snowball has fully melted
    ],
    [],
  );
  useEffect(() => {
    if (isGVAHouse) setSendConfirmationEmail(false);
  }, [isGVAHouse]);

  const [acceptQuote, { loading: acceptLoading }] = useMutation<acceptQuote, acceptQuoteVariables>(
    ACCEPT_QUOTE,
    {
      variables: {
        id: quote.id,
        acceptType,
        journeyData: MANUAL_JOURNEY_DATA,
        acceptedBy: `${me.firstName} ${me.lastName}`,
        skipCustomerEmail: !sendConfirmationEmail,
      },
      onCompleted: () => closeModal(),
      onError: ({ message }) => toast({ type: 'error', message }),
    },
  );

  const [acceptQuoteD2C, { loading: acceptLoadingD2C }] = useMutation<
    acceptD2CQuote,
    acceptD2CQuoteVariables
  >(ACCEPT_D2C_QUOTE, {
    variables: { quoteId: quote.id, acceptType },
    onCompleted: () => closeModal(),
    onError: ({ message }) => toast({ type: 'error', message }),
  });

  const [acceptQuoteBareBones, { loading: acceptLoadingBareBones }] = useMutation<
    acceptQuoteBareBones,
    acceptQuoteBareBonesVariables
  >(ACCEPT_BARE_BONES_QUOTE, {
    variables: { quoteId: quote.id, acceptType },
    onCompleted: () => closeModal(),
    onError: ({ message }) => toast({ type: 'error', message }),
  });

  const [acceptQuoteSubjectToFunding, { loading: acceptSubjectToFundingLoading }] = useMutation<
    acceptSubjectToFunding,
    acceptSubjectToFundingVariables
  >(ACCEPT_QUOTE_SUBJECT_TO_FUNDING, {
    variables: {
      quoteId: quote.id,
      dateFundingExpected: new Date(dateFundingExpected),
      acceptType,
      skipCustomerEmail: !sendConfirmationEmail,
    },
    onCompleted: () => closeModal(),
    onError: ({ message }) => toast({ type: 'error', message }),
  });

  const handleAccept = useCallback(() => {
    if (!acceptType) {
      return window.alert(
        'Er is geen wijze van akkoord aangegeven. Dit is belangrijk voor verdere communicatie en de timing van vervolgacties en herinneringen.',
      );
    }
    const isBareBonesAcceptEnabled = acceptQuoteBareBonesFlag?.enabled;
    return isD2CQuote
      ? acceptQuoteD2C()
      : subjectToFunding
      ? acceptQuoteSubjectToFunding()
      : isBareBonesAcceptEnabled
      ? acceptQuoteBareBones()
      : acceptQuote();
  }, [
    acceptQuote,
    acceptQuoteBareBones,
    acceptQuoteBareBonesFlag?.enabled,
    acceptQuoteD2C,
    acceptQuoteSubjectToFunding,
    acceptType,
    isD2CQuote,
    subjectToFunding,
  ]);

  const loading =
    acceptLoading || acceptSubjectToFundingLoading || acceptLoadingD2C || acceptLoadingBareBones;

  return (
    <Modal
      isOpen={!!isOpen}
      mobile={mobile}
      width="md"
      title="Ter bevestiging van akkoord"
      shouldCloseOnOverlayClick={false}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          disabled: loading,
          bgColor: 'red',
          inverse: true,
          label: 'Terug',
          onClick: closeModal,
        },
        {
          loading,
          label: 'Bevestigen',
          onClick: handleAccept,
          disabled: !acceptType,
        },
      ]}
    >
      <Box>
        {isD2CQuote && (
          <Toast
            type="warning"
            mb={3}
            width="100%"
            message={
              <div>
                <p>
                  Let op: dit betreft een D2C pilot offerte. Er kan geen akkoord onder voorbehoud
                  van financiering worden gegeven. Het proces na akkoord is ook anders dan je gewend
                  bent.
                </p>
                <p>
                  We raden aan de klant zelf via zijn/haar account de offerte te laten accorderen,
                  zodat ze de juiste informatie te zien krijgen.
                </p>
              </div>
            }
          />
        )}

        <p>
          <Medium>
            Voor je de offerte op akkoord kunt zetten, moet je onderstaande vragen beantwoorden.
          </Medium>
        </p>

        {!isD2CQuote && !acceptQuoteBareBonesFlag?.enabled && (
          <>
            <p>
              Wil je een bevestiging per mail naar de klant versturen?{' '}
              {isGVAHouse && <Small>(Niet van toepassing voor GavoorA klanten)</Small>}
            </p>

            <Flex flexWrap="wrap" mx="-3px">
              <Box width={1 / 2} px="3px">
                <Radio
                  id="dontSend"
                  value={false}
                  label="Nee, niet mailen"
                  onChange={() => setSendConfirmationEmail(false)}
                  checked={sendConfirmationEmail === false}
                  width="100%"
                />
              </Box>
              <Box width={1 / 2} px="3px">
                <Radio
                  id="doSend"
                  value={true}
                  label="Ja, stuur bevestiging"
                  onChange={() => setSendConfirmationEmail(true)}
                  checked={sendConfirmationEmail}
                  width="100%"
                  disabled={isGVAHouse}
                />
              </Box>
            </Flex>
          </>
        )}

        <Select
          isClearable={false}
          options={options}
          value={options.find(opt => opt.value === acceptType)}
          onChange={(e: { value: string }) => setAcceptType(e.value)}
          label="Wijze van akkoord"
          placeholder="Kies een optie"
          mb={4}
        />

        {!isD2CQuote && !acceptQuoteBareBonesFlag?.enabled && (
          <>
            <p>
              Vink hieronder aan om een extra notitie te maken bij de deal, zodat de opdracht nog
              niet wordt ingepland.
            </p>
            <RadioGroup
              label="Akkoord is onder voorbehoud van financiering"
              id="isSubject"
              value={true}
              onChange={() => setSubjectToFunding(!subjectToFunding)}
            >
              <Radio
                label="Financiering is rond"
                value={true}
                checked={subjectToFunding === false}
                key="rond"
              />
              <Radio
                label="Onder voorbehoud van financiering"
                value={false}
                checked={subjectToFunding === true}
                key="ovvf"
              />
            </RadioGroup>

            {subjectToFunding && (
              <Box width={1 / 2}>
                <p>
                  <Gray>
                    Er wordt een deal aangemaakt in de 'Onder voorbehoud van financiering' pijplijn
                    en de advies deal wordt naar het stadium 'On hold / voorbehoud van financiering'
                    verplaatst.
                  </Gray>
                </p>
                <label>Verwachtte datum financiering rond</label>
                <Input
                  type="date"
                  min={dayjs().format('YYYY-MM-DD')}
                  label="Datum"
                  value={dateFundingExpected}
                  onChange={e => setDateFundingExpected(e.target.value)}
                />
              </Box>
            )}
          </>
        )}
        {acceptQuoteBareBonesFlag?.enabled && (
          <Toast
            width="100%"
            type="info"
            message={
              <p>
                Let op! Dit akkoord wordt anders verwerkt. Er vindt geen automatisch proces meer
                plaats. In plaats daarvan zal er{' '}
                <a
                  href="https://docs.google.com/spreadsheets/d/1Z0Wn_w8wynjTj8mpSJiNZ8LtY8TDZ1LhMiBI5Pi50Jg/edit?gid=1334738140#gid=1334738140"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  in deze Sheet
                </a>{' '}
                een rij worden toegevoegd met alle benodigde informatie om de opdracht handmatig aan
                een installateur <i>over te dragen</i>.
              </p>
            }
          />
        )}
      </Box>
    </Modal>
  );
};

export default AcceptQuoteModal;
