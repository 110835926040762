import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Button, Card, Flex, Input } from '@energiebespaarders/symbols';
import { Solution } from '../../types/graphql-global-types';
import { SOLUTIONS_NL } from '@energiebespaarders/constants';

type LeadProductCategoryInput = {
  name: string;
  customPrice: number | null;
  isExclusive: boolean | null;
};

type Props = {
  solutions: ReadonlyArray<Solution>;
  onUpdate: (inputs: LeadProductCategoryInput[]) => void;
  leadProductCategories: ReadonlyArray<LeadProductCategoryInput>;
};

const EditLeadGenCategories: React.FC<Props> = ({ solutions, leadProductCategories, onUpdate }) => {
  const initialData = leadProductCategories.reduce<
    Record<string, Omit<LeadProductCategoryInput, 'name'>>
  >((acc, { name, ...rest }) => {
    acc[name] = rest;
    return acc;
  }, {});
  const [formData, setFormData] = useState<Record<string, Omit<LeadProductCategoryInput, 'name'>>>(
    initialData,
  );

  // 🧠 Sync formData when solutions change
  useEffect(() => {
    setFormData(prev => {
      const updated: typeof prev = {};
      for (const name of solutions) {
        updated[name] = prev[name] ?? { customPrice: undefined, isExclusive: false };
      }
      return updated;
    });
  }, [solutions]);

  const handleInputChange = (
    name: string,
    key: 'customPrice' | 'isExclusive',
    value: string | boolean,
  ) => {
    setFormData(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        [key]: key === 'customPrice' ? parseFloat(value as string) || undefined : value,
      },
    }));
  };

  const handleSubmit = () => {
    const result = Object.entries(formData).map(([name, values]) => ({
      name,
      ...values,
    }));
    onUpdate(result);
  };

  return (
    <Box>
      <Flex flexWrap="wrap" mx={-2}>
        {Object.entries(formData).map(([name, data]) => (
          <Box width={[1, 1 / 2, 1 / 3]} px={2} key={name} mb={3}>
            <Card borderColor="grayLight" bgColor="grayLighter" shadow="none" p={3}>
              <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Box fontWeight="bold">{SOLUTIONS_NL[name as Solution]}</Box>
              </Flex>
              <Box mb={2}>
                <Input
                  label="Afwijkende prijs (Laat leeg voor standaard)"
                  type="number"
                  placeholder="Prijs"
                  value={data.customPrice ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(name, 'customPrice', e.target.value)
                  }
                />
              </Box>
              <Box>
                <label style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <input
                    type="checkbox"
                    checked={data.isExclusive ?? false}
                    onChange={e => handleInputChange(name, 'isExclusive', e.target.checked)}
                  />
                  Voor dit product geldt exclusiviteit
                </label>
              </Box>
            </Card>
          </Box>
        ))}
      </Flex>
      <Button label="Opslaan" onClick={handleSubmit} />
    </Box>
  );
};

export default EditLeadGenCategories;
