import { Query } from '@apollo/client/react/components';
import { isUndefined } from '@energiebespaarders/constants';
import { Icon, Placeholder, Tooltip } from '@energiebespaarders/symbols';
import { CancelCircle } from '@energiebespaarders/symbols/icons/solid';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { animated } from '../lib/hoc/withAnimation';
import { withSpacing } from '../lib/hoc/withSpacing';
import { fixUnit, formatDataValue, getValueFromKey } from '../lib/utils';
import { margin, shade, themify } from '../styles/mixins';

export interface IDataField {
  key?: string;
  label: string;
  value: string | number | boolean | Date;
  readOnly?: boolean;
  color?: string;
  unit?: string;
  link?: string;
}

const DataLabel = styled.div<{ $color: string; $labelColor?: string }>`
  flex-grow: 1;
  color: ${x => x.$labelColor || x.$color};
  font-size: ${x => x.theme.type.scale[5]};
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ${x => x.theme.curves.standard};
`;

const DataValue = styled.div<{ $raiseAlarm?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: baseline;
  flex-grow: 1;
  text-align: right;
  font-size: ${x => x.theme.type.scale[5]};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  transition: background 0.2s ${x => x.theme.curves.standard};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 45px;
    height: 100%;
    background: ${x => `linear-gradient(
      to right,
      ${x.$raiseAlarm ? x.theme.colors.orange : 'white'},
      rgba(255, 255, 255, 0)
    )`};
  }
`;

const DataUnit = styled.div<{ $raiseAlarm?: boolean }>`
  ${margin(1, 'left')};
  color: ${x => (x.$raiseAlarm ? 'white' : x.theme.colors.gray)};
  font-size: ${x => x.theme.type.scale[5]};
  white-space: nowrap;
`;

interface DataFieldProps {
  $bgColor: string;
  $color: string;
  $raiseAlarm?: boolean;
  $readOnly: boolean;
}

const StyledDataField = styled.div<DataFieldProps>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  justify-items: stretch;
  padding: 1em;
  height: 3em;
  background: ${x => (x.$raiseAlarm ? x.theme.colors.orange : x.$bgColor)};
  color: ${x => (x.$raiseAlarm ? 'white' : x.$color)};
  text-align: left;
  border-radius: 6px;
  transition: all 0.2s ${x => x.theme.curves.sharp};
  border: 1px solid ${x => (x.$raiseAlarm ? x.theme.colors.red : x.theme.colors.grayLight)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 6px 0 0 6px;
    width: 3px;
    background: ${x => (x.$raiseAlarm ? x.theme.colors.red : x.$color)};
  }

  ${x =>
    !x.$readOnly &&
    css`
      &:hover {
        background: ${x => x.theme.colors.grayLighter};
        box-shadow: 0 1px 3px -1px ${x => rgba(shade(0.8, x.theme.colors.green), 0.2)},
          0 2px 3px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;

        ${DataLabel} {
          overflow: visible;
          white-space: normal;
          text-overflow: initial;
        }

        ${DataValue} {
          overflow: visible;
          white-space: normal;
          text-overflow: initial;

          &::after {
            display: none;
          }
        }
      }

      &:active {
        background: ${x => x.theme.colors.grayLight};

        ${DataValue} {
          &::after {
            display: none;
          }
        }
      }
    `};
`;

export type Option = { label: string; value: string };

export interface DataItem {
  key: string;
  customerId: string;
  label: string;
  type: string;
  options?: Option[];
  optional?: boolean;
  value: string | number | boolean | Date;
  readOnly?: boolean;
}

const getVariable = (dataItem: DataItem, id: string) => {
  const model = dataItem.key.split('.')[0];
  if (model === 'situation') return { houseId: id };
  if (model === 'house' || model === 'customer') return { id };
};

const determineColor = (dataItem: DataItem, values: any, raiseAlarm?: boolean) => {
  const intakeValueDefined = !isUndefined(values.intake, true);
  const defaultValueDefined = !isUndefined(values.default, true) || dataItem.optional;
  const anyValueDefined = intakeValueDefined || defaultValueDefined;

  let dataColor = 'red';
  if (defaultValueDefined) dataColor = 'grayBlack';
  if (intakeValueDefined) dataColor = 'greenDark';
  if (dataItem.key.includes('needs') && anyValueDefined) dataColor = 'greenDark';
  if (dataItem.key.includes('situation') && anyValueDefined) dataColor = 'greenDark';
  if (raiseAlarm) dataColor = 'white';
  return dataColor;
};

const parseValue = (dataItem: DataItem, values: { intake?: any; default: any }) => {
  let arrayLabel: string[] = [];
  if (dataItem.type === 'checkbox') {
    arrayLabel = [];
    const answered = !isUndefined(values.intake, true) ?? !isUndefined(values.default, true);
    const arrayAnswers = dataItem.options?.filter(option =>
      values.intake ? values.intake.includes(option.value) : values.default.includes(option.value),
    );
    arrayAnswers?.forEach((_, index) => arrayLabel.push(arrayAnswers[index].label));
    if (answered && arrayLabel.length === 0) arrayLabel = ['-'];
  }

  const options =
    dataItem.options?.filter(option =>
      !isUndefined(values.intake)
        ? option.value === values.intake
        : option.value === values.default,
    ) || [];

  const optionLabel = options.length > 0 && options[0].label;

  let parsedValue = values.default;
  try {
    if (arrayLabel.length > 0) parsedValue = arrayLabel.join(', ');
    else if (optionLabel) parsedValue = optionLabel;
    else if (values.intake !== null && values.intake !== undefined) parsedValue = values.intake;
  } catch (error) {
    console.log('ERROR AT ', dataItem);
    parsedValue = '';
  }

  return parsedValue;
};

const isLongValue = (parsedValue: string) =>
  Array.isArray(parsedValue) ? parsedValue.join(', ').length > 30 : String(parsedValue).length > 30;

const DataField = ({
  bgColor = 'white',
  color,
  dataItem,
  modelId,
  hideEmpty,
  manual,
  onClick,
  readOnly = true,
}: any & { dataItem: DataItem }) => {
  return manual || !dataItem || !modelId ? (
    <StyledDataField
      $bgColor={themify(bgColor)}
      $color={themify(manual ? color : 'red')}
      $readOnly={readOnly}
      onClick={readOnly ? null : onClick}
    >
      <DataLabel $color={themify(manual ? color : 'red')}>
        {manual ? manual.label : 'Data ontbreekt'}
      </DataLabel>
      <DataValue>
        {manual ? (
          formatDataValue(manual.value, manual.unit)
        ) : (
          <Icon icon={CancelCircle} fill="red" solid />
        )}
        {manual && manual.unit && <DataUnit>{fixUnit(manual.unit)}</DataUnit>}
      </DataValue>
    </StyledDataField>
  ) : (
    dataItem.query && (
      <Query query={dataItem.query} variables={getVariable(dataItem, modelId)}>
        {({ loading, data, error, refetch }: any) => {
          if (loading || error || !data) {
            return <Placeholder error={error} mb={2} />;
          }
          const value = getValueFromKey(dataItem.key, data);

          // NOTE: Intake value is only for house.intake, NOT for house.situation or customer.needs
          const intakeValue = getValueFromKey(dataItem.key.replace('house', 'intake'), data.house);

          const raiseAlarm = dataItem.key.indexOf('.rc') > -1 && intakeValue === 0;

          const dataColor = determineColor(
            dataItem,
            { intake: intakeValue, default: value },
            raiseAlarm,
          );
          const parsedValue = parseValue(dataItem, { intake: intakeValue, default: value });
          const intakeValueDefined = !isUndefined(intakeValue, true);
          const defaultValueDefined = !isUndefined(value, true) || dataItem.optional;
          const anyValueDefined = intakeValueDefined || defaultValueDefined;
          if (!anyValueDefined && hideEmpty) return null; // TODO: Refactor this into two separate FCs – one with query and one with manual value

          const renderDataField = () => (
            <StyledDataField
              $bgColor={themify(bgColor)}
              $color={themify(dataColor)}
              $raiseAlarm={raiseAlarm}
              $readOnly={readOnly || dataItem.locked}
              onClick={
                readOnly || dataItem.locked
                  ? () => refetch()
                  : () => onClick(dataItem.label, parsedValue, dataItem.unit)
              }
            >
              <DataLabel $color={themify(dataColor)}>{dataItem.label}</DataLabel>
              <DataValue $raiseAlarm={raiseAlarm}>
                {formatDataValue(parsedValue, dataItem.unit, dataItem.optional)}
                {dataItem.unit && (
                  <DataUnit $raiseAlarm={raiseAlarm}>{fixUnit(dataItem.unit)}</DataUnit>
                )}
              </DataValue>
            </StyledDataField>
          );
          if (anyValueDefined || !hideEmpty) {
            return isLongValue(parsedValue) ? (
              <Tooltip block content={formatDataValue(parsedValue, dataItem.unit)}>
                {renderDataField()}
              </Tooltip>
            ) : (
              renderDataField()
            );
          }
          return <></>;
        }}
      </Query>
    )
  );
};

export default animated(withSpacing(DataField), { animation: 'floatIn' });
